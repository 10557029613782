<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p data-identifyelement="478">Please note that Express VPN is no longer supported. We recommand using Surfshark VPM instead.</p>
								<p data-identifyelement="478">Additionally, It is better to use an Android TV that has Google Play Store and Google TV for optimal performance.</p>
								<br />

								<p data-identifyelement="478">Other hardware we recommend is;</p>
								<p data-identifyelement="479"><br data-identifyelement="480" /></p>
								<p data-identifyelement="481">
									Fire Stick 4K $49 (not normal firestick)<br data-identifyelement="482" /><br data-identifyelement="483" /><img
										src="../../../assets/images/mini-icon/remote-device.png"
										class="fr-fic fr-dib fr-fil"
										data-filelink="../../../assets/images/mini-icon/remote-device.png"
										data-fileid="48002477361"
										data-uniquekey="1564361464606"
										style="width: 100%"
										data-identifyelement="484"
									/><br data-identifyelement="485" />
								</p>
								<p data-identifyelement="486">Mi box S Xiaomi available available for 60$</p>
								<p data-identifyelement="487">
									<img
										src="../../../assets/images/mini-icon/remote-and-mi-device.jpg"
										class="fr-fic fr-dib fr-fil"
										data-filelink="../../../assets/images/mini-icon/remote-and-mi-device.jpg"
										data-fileid="48002053009"
										data-uniquekey="1563835942230"
										style="width: 100%"
										data-identifyelement="488"
									/>
								</p>
								<p data-identifyelement="489">&nbsp;</p>
								<p data-identifyelement="490">Nvidia Shield 200$</p>
								<p data-identifyelement="491">
									<img
										src="../../../assets/images/mini-icon/gaming-remote.jpg"
										class="fr-fic fr-dib fr-fil"
										data-filelink="../../../assets/images/mini-icon/gaming-remote.jpg"
										data-fileid="48002053015"
										data-uniquekey="1563835942230"
										style="width: 100%"
										data-identifyelement="492"
									/>
								</p>
								<p data-identifyelement="493"><br /></p>
								<p data-identifyelement="493"><br /></p>
								<p data-identifyelement="493"><br /></p>
								<p data-identifyelement="493" dir="ltr">Chromecast with Google TV $50</p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="493" dir="ltr">
									<img
										src="../../../assets/images/mini-icon/chromecast.png"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48179908693"
									/>
								</p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="493" dir="ltr">Walmart ONN Android TV $20&nbsp;</p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="493" dir="ltr">
									<img
										src="../../../assets/images/mini-icon/omnwatch.png"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48179908765"
									/>
								</p>
								<p data-identifyelement="493" dir="ltr"><br /></p>
								<p data-identifyelement="495" dir="ltr">
									We do <strong data-identifyelement="496"><u data-identifyelement="497">NOT</u></strong> recommend using Samsung or LGTV apps since its no longer
									supported.&nbsp;
								</p>
								<p data-identifyelement="495" dir="ltr">For best experience with our system use one of the above recommended devices.&nbsp;</p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'whatIsThePrice',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
